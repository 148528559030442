<template>
  <div>
    <div>
      <div class="menu">
        <a @click="pushRoute('company')" class="logo cursor-pointer">
          <img src="@/assets/images/logo-up.png" />
        </a>
        <!-- :contractStatus="contractStatus" -->
        <MenuItems
          :company="this.company"
          :menuSelected="this.menuSelected"
          :user="this.user"
        />
      </div>

      <div class="menu-mobile">
        <div class="user-mobile">
          <div class="close-menu" @click="openMenu(false)">
            <img src="@/assets/images/x.svg" />
          </div>

          <div>
            <img src="@/assets/images/settings.png" />
          </div>
          <div>
            <a href="#">{{ this.company.name | titleCase }}</a>
          </div>
        </div>
        <!-- :contractStatus="contractStatus" -->
        <MenuItems
          :company="this.company"
          :menuSelected="this.menuSelected"
          :user="this.user"
        />
      </div>

      <div class="container-right">
        <div
          class="barTop"
          :class="menuSelected.dashboard ? 'view' : 'bar-top-white'"
        >
          <div class="logo-mobile" v-if="menuSelected.dashboard">
            <img src="@/assets/images/logo-up.png" />
          </div>
          <div
            class="menu-white"
            v-if="menuSelected.dashboard"
            @click="openMenu(true)"
          >
            <img src="@/assets/images/menu-burger-alb.svg" />
          </div>

          <div class="mobile-nav" v-if="!menuSelected.dashboard">
            <div class="arrow-back" @click="goBack()">
              <img src="@/assets/images/arrow-back.svg" />
            </div>
            <div class="page-title">
              <h3>{{ this.getPageTitle() }}</h3>
            </div>
            <div class="menu-white" @click="openMenu(true)">
              <img src="@/assets/images/menu-burger-gri.svg" />
            </div>
          </div>
          <TopBar :company="this.company" :user="this.user" />
        </div>

        <div
          class="content"
          :class="this.menuSelected.cardTransactions && 'dashboard-beneficiary'"
        >
          <CardTransactions v-if="this.menuSelected.cardTransactions" />

          <Contact :customer="this.company" v-if="this.menuSelected.contact" />
          <CompanySettings
            :companyDetails="this.company"
            :user="this.user"
            :userRole="this.company.role"
            v-if="this.menuSelected.account"
          />
        </div>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')">
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment/src/moment";
import MenuItems from "@/components/company/integrator/MenuItems.vue";
import TopBar from "@/components/company/TopBar.vue";
import CardTransactions from "@/components/company/integrator/CardTransactions.vue";
import CompanySettings from "@/components/company/integrator/CompanySettings.vue";
import Contact from "@/components/company/customer/Contact.vue";
import $ from "jquery";
import httpService from "@/http/http-common.js";

export default {
  components: {
    MenuItems,
    TopBar,
    CardTransactions,
    Contact,
    CompanySettings
  },
  props: {
    user: Object,
    company: Object
  },
  data() {
    return {
      userLink: "",
      token: "",
      menuSelected: {
        cardTransactions: false,
        account: false,
        contact: false
      },

      isOpen: false,
      config: JSON.parse(sessionStorage.getItem("client_jsonFile"))
    };
  },
  methods: {
    openMenu(open) {
      if (open) {
        $(".menu-mobile").show();
      } else {
        $(".menu-mobile").hide();
      }
    },
    openUserMenu() {
      this.isOpen = !this.isOpen;
    },
    hide() {
      this.isOpen = false;
    },
    hideNotification() {
      this.isOpenNotification = false;
    },
    getPageTitle() {
      if (this.menuSelected.cardTransactions) {
        return "Card Transactions";
      } else if (this.menuSelected.account) {
        return "My Account";
      } else if (this.menuSelected.contact) {
        return "Contact";
      } else {
        return "Home";
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    getExpireAmountDateFormat(date) {
      return moment(new Date(date), "YYYY-MM-DD").format("DD MMM YYYY");
    }
  },
  mounted() {
    // prevent click outside event with popupItem.
    if (!sessionStorage.getItem("client_jsonFile")) {
      httpService.commonsService.getConfigFile(localStorage.language, "client");
    }
  },
  created() {
    switch (this.$route.name) {
      case "integr_cardTransactions":
        this.menuSelected.cardTransactions = true;
        break;
      case "integr_account":
        this.menuSelected.account = true;
        break;
      case "integr_contact":
        this.menuSelected.contact = true;
        break;
      default:
        this.menuSelected.cardTransactions = true;
    }
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
