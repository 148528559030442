import axios from "axios";

let beneficiarySvc = process.env.VUE_APP_CARD_USER_API_URL;
let affiliateSvc = process.env.VUE_APP_COMPANY_API_URL;
let companyRoot = process.env.VUE_APP_COMPANY_USER_ROOT;
let userRoot = process.env.VUE_APP_CARD_USER_ROOT;

axios.defaults.headers.common["api_key"] =
	process.env.VUE_APP_CARD_USER_API_KEY;

const integratorAxiosinstance = axios.create({
	baseURL: affiliateSvc,
});
const beneficiaryAxiosInstance = axios.create({
	baseURL: beneficiarySvc,
});

export default {
	integratorService: {
		searchTransactions(transactionFilter, token) {
			return integratorAxiosinstance.get(
				companyRoot +
				"/v1/affiliates/" +
				0 +
				"/external_transactions/search" +
				transactionFilter,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		refundTransaction(transaction, token) {
			return beneficiaryAxiosInstance.put(
				userRoot +
				"/v2/accounts/" +
				transaction.account_id +
				"/operations/" +
				transaction.transaction_ref,
				{
					amount: transaction.amount * -1,
				},
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getTransactionFile(searchParams, token) {
			return integratorAxiosinstance.get(
				companyRoot +
				"/v1/affiliates/" +
				0 +
				"/external_transactions_file" +
				searchParams,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
	},
};
