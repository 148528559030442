import { render, staticRenderFns } from "./CardTransactions.vue?vue&type=template&id=4abfa7de&scoped=true"
import script from "./CardTransactions.vue?vue&type=script&lang=js"
export * from "./CardTransactions.vue?vue&type=script&lang=js"
import style0 from "@/assets/styles/beneficiary.css?vue&type=style&index=0&id=4abfa7de&prod&scoped=true&lang=css&external"
import style1 from "./CardTransactions.vue?vue&type=style&index=1&id=4abfa7de&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4abfa7de",
  null
  
)

export default component.exports