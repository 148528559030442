<template>
  <div>
    <table
      cellpadding="10"
      cellspacing="0"
      width="95%"
      class="hover-table invoices-table"
      align="center"
    >
      <thead>
        <tr>
          <td valign="middle">
            <strong>{{ $t("idStoreLabel") }}</strong>
            <img
              src="@/assets/images/arrow-down-fill.svg"
              @click="sortBy('affiliate_id')"
              :style="
                sortByField === 'affiliate_id' &&
                reverse &&
                'transform: rotate(180deg)'
              "
            />
          </td>
          <td valign="middle">
            <strong>{{ $t("nameStoreLabel") }}</strong>
            <img
              src="@/assets/images/arrow-down-fill.svg"
              @click="sortBy('name')"
              :style="
                sortByField === 'name' && reverse && 'transform: rotate(180deg)'
              "
            />
          </td>
          <td class="priority-1" valign="middle">
            <strong>{{ $t("cardTransactionFilterFiscalCode") }}</strong>
          </td>
          <td class="priority-1" valign="middle">
            <strong>{{ $t("localityLabel") }}</strong>
          </td>

          <td class="priority-3" valign="middle">
            <strong>{{ $t("dateLabel") }}</strong>
            <img
              src="@/assets/images/arrow-down-fill.svg"
              @click="sortBy('date')"
              :style="
                sortByField === 'date' && reverse && 'transform: rotate(180deg)'
              "
            />
          </td>
          <td class="priority-4" valign="middle">
            <strong>{{ $t("typeLabel") }}</strong>
            <img
              src="@/assets/images/arrow-down-fill.svg"
              @click="sortBy('type')"
              :style="
                sortByField === 'type' && reverse && 'transform: rotate(180deg)'
              "
            />
          </td>
          <td class="priority-5" valign="middle">
            <strong>{{ $t("valueLabel") }}</strong>
            <img
              src="@/assets/images/arrow-down-fill.svg"
              @click="sortBy('value')"
              :style="
                sortByField === 'value' &&
                reverse &&
                'transform: rotate(180deg)'
              "
            />
          </td>
          <td class="priority-6" valign="middle">
            <strong>{{ $t("firstNameLabel") }}</strong>
          </td>
          <td class="priority-6" valign="middle">
            <strong>{{ $t("lastNameLabel") }}</strong>
          </td>
          <td class="priority-6" valign="middle">
            <strong>{{ $t("usernameLabel") }}</strong>
          </td>
          <td class="priority-5" valign="middle">
            <!-- <strong>{{ $t("valueLabel") }}</strong> -->
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-if="this.companyTransactions === null">
          <td colspan="6">
            <div class="loading">
              <img src="@/assets/images/loading.gif" />
            </div>
          </td>
        </tr>
        <template v-for="(transaction, index) in companyTransactions">
          <tr
            :key="index"
            class="trhover parent"
            :class="index % 2 === 0 && 'gri-cell'"
          >
            <td valign="middle">
              <a
                class="detailsOrderMobile priority-show-6 display-inline-block"
                @click="selectTransactionMobile(index)"
              >
                <font-awesome-icon
                  icon="minus-circle"
                  v-if="
                    detailsMobile && transaction === transactionSelectedMobile
                  "
                />
                <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
              </a>
              {{ transaction.affiliate.affiliate_id }}
            </td>
            <td valign="middle">
              {{ transaction.affiliate.name.trim() }}
            </td>
            <td valign="priority-1">
              {{ transaction.affiliate.vat_code.trim() }}
            </td>
            <td valign="middle" class="priority-1">
              {{ transaction.affiliate.address.locality.trim() }}
            </td>

            <td valign="middle" class="priority-3">
              {{ displayDate(transaction.date) }}
            </td>
            <td valign="middle" class="priority-4">
              {{ transaction.transaction_type }}
            </td>
            <td valign="middle" class="priority-5">
              {{ transaction.amount }} {{ $t("currency") }}
            </td>
            <td valign="middle" class="priority-6">
              {{ transaction.first_name }}
            </td>
            <td valign="middle" class="priority-6">
              {{ transaction.last_name }}
            </td>
            <td valign="middle" class="priority-6">
              {{ transaction.username }}
            </td>
            <td valign="middle">
              <a
                href="#"
                class="notification-action padding-5"
                @click="refundTransaction(transaction)"
                v-if="
                  transaction.transaction_status === 'ACCEPTED' ||
                  transaction.transaction_status === 'INVOICED'
                "
                >{{ $t("refundButtonLabel") }}
              </a>
              <p v-if="transaction.transaction_status === 'REJECTED'">
                {{ $t("rejectedLabel") }}
                <br />
                ({{ transaction.reason }})
              </p>
              <p v-if="transaction.transaction_status === 'INVOICED'">
                {{ $t("invoicedLabel") }}
              </p>
              <p v-if="transaction.transaction_status === 'REFUNDED'">
                {{ $t("refundedLabel") }}
              </p>
              <p v-if="transaction.transaction_status === 'PROCESSING'">
                {{ $t("processingLabel") }}
              </p>
            </td>
          </tr>
          <tr
            :key="index + companyTransactions.length"
            class="child"
            :class="index % 2 === 0 && 'gri-cell'"
            v-show="detailsMobile && transaction === transactionSelectedMobile"
          >
            <td colspan="12">
              <p class="priority-show-1">
                <strong>{{ $t("localityLabel") }} : &nbsp;&nbsp;</strong>
                <span>{{ transaction.affiliate.address.locality.trim() }}</span>
              </p>

              <p class="priority-show-3">
                <strong>{{ $t("dateLabel") }} : &nbsp;&nbsp;</strong>
                <span>{{ displayDate(transaction.date) }}</span>
              </p>
              <p class="priority-show-4">
                <strong>{{ $t("typeLabel") }} : &nbsp;&nbsp;</strong>
                <span>{{ transaction.transaction_type }}</span>
              </p>
              <p class="priority-show-5">
                <strong>{{ $t("valueLabel") }} : &nbsp;&nbsp;</strong>
                <span>{{ transaction.amount }} {{ $t("currency") }}</span>
              </p>
              <p class="priority-show-6">
                <strong>{{ $t("firstNameLabel") }} : &nbsp;&nbsp;</strong>
                <span>{{ transaction.first_name }} </span>
              </p>
              <p class="priority-show-6">
                <strong>{{ $t("lastNameLabel") }} : &nbsp;&nbsp;</strong>
                <span>{{ transaction.last_name }} </span>
              </p>
              <p class="priority-show-6">
                <strong>{{ $t("usernameLabel") }} : &nbsp;&nbsp;</strong>
                <span>{{ transaction.username }}</span>
              </p>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div id="modal_popup_refundSuccess" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_refundSuccess', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="!transactionError">
          <h3>{{ $t("refundSucccessMessage") }}</h3>
        </div>

        <div v-else>
          <p>{{ $t("refundErrorMessage_" + transactionError.code) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import httpServiceIntegr from "@/http/http-integr.js";
import Cookies from "js-cookie";

export default {
  name: "CardTransactionTable",
  props: {
    companyTransactions: Array,
    company: Object,
    msg: String,
  },
  data() {
    return {
      detailsMobile: false,
      transactionSelectedMobile: null,
      sortByField: "date",
      reverse: false,
      transactionError: null,
    };
  },
  methods: {
    displayDate(date) {
      return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format(
        "DD/MM/YYYY HH:mm"
      );
    },
    selectTransactionMobile(index) {
      this.transactionSelectedMobile = this.companyTransactions[index];
      this.detailsMobile = !this.detailsMobile;
    },
    sortBy(fieldName) {
      if (this.sortByField !== fieldName) {
        this.reverse = false;
      }
      this.sortByField = fieldName;
      this.reverse = !this.reverse;
      this.$emit("change", fieldName, this.reverse ? "asc" : "desc");
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    refundTransaction(transaction) {
      this.transactionError = null;
      this.showModalPopup("modal_popup_loading", true);

      httpServiceIntegr.integratorService
        .refundTransaction(transaction, Cookies.get("token"))
        .then(function (response) {
          return response;
        })
        .then((response) => {
          if (response.status === 200) {
            // this.transactionCard = response.data;
            // this.transactionsCount = response.headers["x-up-count"];
            transaction.transaction_status = "REFUNDED";
            this.showModalPopup("modal_popup_refundSuccess", true);
          } else if (response.status === 400) {
            this.transactionError = { code: response.data.error_code };
          } else {
            this.transactionError = { code: response.error_code };
          }
          this.showModalPopup("modal_popup_loading", false);
        })
        .catch((error) => {
          if (error.request.status === 400) {
            this.transactionError = { code: error.response.data.error_code };
          } else {
            this.transactionError = { code: "500" };
          }
          this.showModalPopup("modal_popup_refundSuccess", true);
          this.showModalPopup("modal_popup_loading", false);
        });
    },
  },
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
