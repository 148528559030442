<template>
  <div>
    <div class="container-alb filter-mobile">
      <table
        cellpadding="10"
        cellspacing="0"
        width="95%"
        class="hover-table"
        align="center"
      >
        <tr>
          <td>
            <img src="@/assets/images/filter.svg" />
            <span class="mobile-show margin-left-5">{{
              $t("invoicesFilterTitle")
            }}</span>
          </td>
          <td>
            {{ $t("cardTransactionFilterProduct") }}
            <select class="filtrare" @change="productSelected">
              <option
                v-for="(entry, index) in cardProducts"
                :key="index"
                :value="entry.card"
                :selected="isSelectedProduct(entry.card)"
              >
                {{ entry.name }}
              </option>
            </select>
          </td>
          <td>
            {{ $t("cardTransactionFilterFiscalCode") }}
            <form
              @submit.prevent="
                {
                  return false;
                }
              "
            >
              <input
                type="text"
                class="formedituser"
                v-model="filters.fiscal_code"
                v-on:keyup.enter="getCardTransaction()"
              />
            </form>
          </td>
          <td>
            {{ $t("cardTransactionFilterAffiliateName") }}
            <form
              @submit.prevent="
                {
                  return false;
                }
              "
            >
              <input
                type="text"
                class="formedituser"
                v-model="filters.affiliate_name"
                v-on:keyup.enter="getCardTransaction()"
              />
            </form>
          </td>
          <td>
            <form
              @submit.prevent="
                {
                  return false;
                }
              "
            >
              {{ $t("cardTransactionFilterTransactionType") }}
              <select
                @change="getCardTransaction"
                class="filtrare"
                v-model="filters.tr_type"
              >
                <option
                  v-for="(entry, index) in transactionTypes"
                  :key="index"
                  :value="entry"
                  :selected="filters.tr_type === entry"
                >
                  {{ entry }}
                </option>
              </select>
            </form>
          </td>
          <td>
            {{ $t("filterLaberTransactionId") }}
            <form
              @submit.prevent="
                {
                  return false;
                }
              "
            >
              <input
                type="number"
                class="formedituser"
                v-model="filters.id"
                v-on:keyup.enter="getCardTransaction()"
              />
            </form>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            {{ $t("invoicesFilterPeriod") }}
            <date-range
              class="invoices-filter"
              @close="periodFilterChanged"
              :time="this.filters.period"
            />
          </td>
          <td>
            {{ $t("firstNameLabel") }}
            <form
              @submit.prevent="
                {
                  return false;
                }
              "
            >
              <input
                type="text"
                class="formedituser"
                v-model="filters.first_name"
                v-on:keyup.enter="getCardTransaction()"
              />
            </form>
          </td>
          <td>
            {{ $t("lastNameLabel") }}
            <form
              @submit.prevent="
                {
                  return false;
                }
              "
            >
              <input
                type="text"
                class="formedituser"
                v-model="filters.last_name"
                v-on:keyup.enter="getCardTransaction()"
              />
            </form>
          </td>
          <td colspan="2">
            {{ $t("usernameLabel") }}
            <form
              @submit.prevent="
                {
                  return false;
                }
              "
            >
              <input
                type="text"
                class="formedituser"
                v-model="filters.username"
                v-on:keyup.enter="getCardTransaction()"
              />
            </form>
          </td>
        </tr>
      </table>
    </div>

    <!-- card transaction -->
    <div class="container-alb">
      <div class="table-header-wrapper">
        <div class="left">
          <h3 class="table-title d-inline-block">
            {{ $t("cardTransactionTitle") }}
          </h3>
          <img
            class="download d-inline-block"
            @click="getExcelTransaction()"
            src="@/assets/images/download.svg"
          />
        </div>
      </div>
      <br />

      <CardTransactionTable
        :companyTransactions="this.transactionCard"
        @change="sortBy(sortField, sortDirection)"
      />

      <div
        class="error-wrapper border-box"
        v-if="this.transactionCard && this.transactionCard.length === 0"
      >
        <p>{{ $t("noTransactionMessage") }}</p>
      </div>

      <div class="error-wrapper border-box" v-if="this.transactionError">
        <p>{{ $t("errorTransactionMessage") }}</p>
      </div>

      <div class="pagination-wrapper border-box">
        <b-pagination
          v-model="currentPage"
          :total-rows="this.transactionsCount"
          :per-page="perPage"
          size="sm"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import CardTransactionTable from "@/components/company/integrator/CardTransactionsTable.vue";
import utils from "@/plugins/utils.js";

import DateRange from "@/components/DateRange.vue";
import httpServiceIntegr from "@/http/http-integr.js";
import { BPagination } from "bootstrap-vue";
import Cookies from "js-cookie";
import moment from "moment";
import { saveAs } from "file-saver";

export default {
  props: {
    msg: String,
  },
  components: {
    CardTransactionTable,
    DateRange,
    "b-pagination": BPagination,
    // Multiselect
  },
  data() {
    return {
      transactionError: null,
      transactionCard: null,
      tempInvoices: null,
      filters: {
        product: 11,
        fiscal_code: "",
        affiliate_name: "",
        tr_type: "",
        username: "",
        first_name: "",
        last_name: "",
        id: null,
        // tr_status: INVOICED
        period: [moment(), moment()],
      },
      transactionTypes: ["", "DEBIT", "CREDIT"],
      transactionsCount: 10,
      perPage: 10,
      currentPage: 1,
      sortField: "date",
      sortDirection: "desc",
      selectedProduct: null,
      error: null,
      config: JSON.parse(sessionStorage.getItem("client_jsonFile")),
      cardProducts: [
        {
          brand: "dejun",
          card: 11,
          name: "Up Dejun",
          order: 1,
          paper: 0,
          perso: true,
        },
        {
          brand: "cadou",
          card: 13,
          name: "Up Cadou",
          order: 2,
          paper: 0,
          perso: true,
        },
      ],
    };
  },
  methods: {
    productSelected(event) {
      this.filters.product = event.target.value;
      this.getCardTransaction();
    },
    periodFilterChanged(value) {
      this.filters.period = value;
      this.getCardTransaction();
    },
    isSelectedProduct(product) {
      return product === this.filters.product;
    },
    sortBy(sortField, sortDirection, status) {
      this.sortField = sortField;
      this.sortDirection = sortDirection;
      this.getCardTransaction(status);
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    getCardTransaction() {
      this.transactionCard = null;
      var transactionData =
        "?product=" +
        this.filters.product +
        "&tr_type=" +
        this.filters.tr_type +
        (this.filters.fiscal_code.trim().length > 0
          ? "&fiscal_code=" + this.filters.fiscal_code
          : "") +
        (this.filters.affiliate_name.trim().length > 0
          ? "&name=" + this.filters.affiliate_name
          : "") +
        (this.filters.username.trim().length > 0
          ? "&username=" + this.filters.username
          : "") +
        (this.filters.first_name.trim().length > 0
          ? "&first_name=" + this.filters.first_name
          : "") +
        (this.filters.last_name.trim().length > 0
          ? "&last_name=" + this.filters.last_name
          : "") +
        (this.filters.id > 0 ? "&id=" + this.filters.id : "") +
        "&start_date=" +
        moment(this.filters.period[0]).format("YYYYMMDD") +
        "&end_date=" +
        moment(this.filters.period[1]).format("YYYYMMDD");
      transactionData +=
        "&per_page=" +
        this.perPage +
        "&page=" +
        this.currentPage +
        "&sort=" +
        this.sortDirection;
      if (this.sortDirection === "desc") {
        transactionData += "&desc=" + this.sortField;
      } else {
        transactionData += "&asc=" + this.sortField;
      }
      // this.showModalPopup("modal_popup_loading", true);

      httpServiceIntegr.integratorService
        .searchTransactions(transactionData, Cookies.get("token"))
        .then(function (response) {
          return response;
        })
        .then((response) => {
          if (response.status == 200) {
            this.transactionCard = response.data;
            this.transactionsCount = response.headers["x-up-count"];
          } else if (response.status == 400) {
            this.transactionError = { code: response.data.code };
          } else {
            this.transactionError = { code: response.code };
          }
          // this.showModalPopup("modal_popup_loading", false);
        })
        .catch((error) => {
          this.transactionError = { code: "500" };
          // this.showModalPopup("modal_popup_loading", false);
        });
    },
    selectProduct(index) {
      this.selectedProduct = this.cardProducts[index];
    },
    getExcelTransaction() {
      this.showModalPopup("modal_popup_loading", true);

      var transactionData =
        "?product=" +
        this.filters.product +
        "&tr_type=" +
        this.filters.tr_type +
        (this.filters.fiscal_code.trim().length > 0
          ? "&fiscal_code=" + this.filters.fiscal_code
          : "") +
        (this.filters.affiliate_name.trim().length > 0
          ? "&name=" + this.filters.affiliate_name
          : "") +
        (this.filters.username.trim().length > 0
          ? "&username=" + this.filters.username
          : "") +
        (this.filters.first_name.trim().length > 0
          ? "&first_name=" + this.filters.first_name
          : "") +
        (this.filters.last_name.trim().length > 0
          ? "&last_name=" + this.filters.last_name
          : "") +
        (this.filters.id > 0 ? "&id=" + this.filters.id : "") +
        "&start_date=" +
        moment(this.filters.period[0]).format("YYYYMMDD") +
        "&end_date=" +
        moment(this.filters.period[1]).format("YYYYMMDD");
      transactionData +=
        "&per_page=" + 100000 + "&page=" + 1 + "&sort=" + this.sortDirection;
      if (this.sortDirection === "desc") {
        transactionData += "&desc=" + this.sortField;
      } else {
        transactionData += "&asc=" + this.sortField;
      }

      return httpServiceIntegr.integratorService
        .getTransactionFile(transactionData, Cookies.get("token"))
        .then((response) => {
          if (response.status === 200) {
            var blob = new Blob(
              [utils.base64ToArrayBuffer(response.data.content)],
              {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel",
              }
            );
            saveAs(blob, response.data.name);
          } else if (response.status === 400 || response.status === 403) {
            this.transactionError = { code: 500 };
          } else {
            this.transactionError = { code: 500 };
          }
          this.showModalPopup("modal_popup_loading", false);
        })
        .catch((error) => {
          this.transactionError = { code: 500 };
          this.showModalPopup("modal_popup_loading", false);
        });
    },
  },
  // computed: {
  //   cardProducts() {
  //     let cardProducts = [];
  //     for (let index in this.config.products) {
  //       if (this.config.products[index].card > 0) {
  //         cardProducts.push(this.config.products[index]);
  //       }
  //     }
  //     return cardProducts;
  //   },
  // },
  mounted() {
    this.filters.product = this.config.products[0].card;
    this.transactionCard = this.getCardTransaction();
  },
  created() {
    this.selectProduct(0);
  },
  watch: {
    currentPage: function () {
      this.getCardTransaction();
    },
  },
};
</script>

<style scoped src="@/assets/styles/beneficiary.css"></style>
<style>
.page-link {
  color: #4a4a4a;
}
.page-link:hover {
  color: #4e5b59;
}
.page-item.active .page-link {
  background-color: #4e5b59 !important;
  border-color: #4e5b59 !important;
}
.invoices-filter .reportrange-text {
  height: 27px;
  font-size: 13px;
  padding: 3px 10px;
  border: none !important;
  color: #585858;
  background: #f1f1f1;
}
</style>
